import { GetServerSideProps } from 'next';
import { getUserTokenFromIncomingMessage } from '@ktech/cookie-jar';
import { createRelayEnvironment } from '@ktech/relay';
import Container from '@mui/material/Container';

import TournamentsPublicLayout, {
    TournamentsPublicLayoutQuery,
    TournamentsPublicLayoutQueryType,
} from '@/components/tournament/public/TournamentsPublicLayout';
import { fetchQuery } from 'react-relay';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { useSearchParams } from 'next/navigation';
import PastFutureTournamentTabs from '@/components/tournament/public/PastFutureTournamentTabs';

export const getServerSideProps: GetServerSideProps<{
    records: RecordMap;
}> = async context => {
    const environment = createRelayEnvironment({
        nodeServiceHost: context.req.headers.host || process.env.NEXT_PUBLIC_NODE_SERVICE_HOST,
        authorization: getUserTokenFromIncomingMessage(context.req),
        searchParams: { locale: context.locale || '' },
    });

    const page = Number(context.query.page) || 1;
    const search = context.query.search ? String(context.query.search) : null;
    const countryCode = context.query.countryCode ? String(context.query.countryCode) : null;
    const first = 0;
    const futureTournaments = true;
    const orderDirection = 'ASC';

    await fetchQuery<TournamentsPublicLayoutQueryType>(environment, TournamentsPublicLayoutQuery, {
        page,
        first,
        futureTournaments,
        orderDirection,
        filter: {
            name: search ? { _like: search } : null,
            address: countryCode ? { countryCode: { _eq: countryCode } } : null,
        },
    }).toPromise();

    const records = environment.getStore().getSource().toJSON();

    return {
        props: {
            records,
        },
    };
};

const PageIndex = () => {
    const searchParams = useSearchParams();
    const page = Number(searchParams.get('page')) || 1;
    const search = searchParams.get('search') || '';
    const countryCode = searchParams.get('countryCode') || '';

    return (
        <Container>
            <PastFutureTournamentTabs />
            <TournamentsPublicLayout
                first={0}
                page={page}
                futureTournaments={true}
                orderDirection="ASC"
                search={search}
                countryCode={countryCode}
            />
        </Container>
    );
};

export default PageIndex;
